/* Provide sufficient contrast against white background */

body {
  overflow: hidden;
}
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}
#sv-nav-complete {
  display: none;
}

.btn-primary {
  color: #fff;
  background-color: #1e9cff;
  border-color: #1e9cff;
  font-size: 14px;
  width: 120px;
  height: 36px;
  border-radius: 4px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #1e9cff;
  border-color: #1e9cff;
}

.btn-primary:disabled {
  color: #fff;
  background-color: #b1b1b1;
  border-color: #b1b1b1;
}

.btn-primary-outline {
  color: #1e9cff;
  background-color: transparent;
  border-color: #1e9cff;
  font-size: 14px;
  width: 120px;
  height: 36px;
  border-radius: 4px;
}

.btn-primary-outline:hover {
  color: #1e9cff;
  background-color: transparent;
  border-color: #1e9cff;
}

.btn-delete {
  color: #fff;
  background-color: red;
  border-color: red;
  font-size: 14px;
  width: 120px;
  height: 36px;
  border-radius: 4px;
}

.btn-delete:hover {
  color: #fff;
  background-color: red;
  border-color: red;
}

.btn-normal {
  color: #fff;
  background-color: #b1b1b1;
  border-color: #b1b1b1;
  font-size: 14px;
  width: 120px;
  height: 36px;
  border-radius: 4px;
}

.btn-normal:hover {
  color: #fff;
  background-color: #b1b1b1;
  border-color: #b1b1b1;
}

.report-style-class {
  height: 70vh;
  margin: 1% auto;
  width: 100%;
}
